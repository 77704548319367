<template>
  <div class="content-mail-appel-commercial">
    <div class="data-mail-filiale-selected">
      <div v-if="error != null" class="error-message text-center ">
        <span>{{ error }}</span>
      </div>
      <template v-if="error == null && loader == false && dataToUse != null">
        <v-row>
          <v-col cols="2">
            <v-switch
              class="check-style-custom-obligee-mail"
              v-if="dataToUse != null"
              v-model="dataToUse.send_to_client"
              value="yes"
              unchecked-value="no"
              color="#704ad1"
            >
              <template v-slot:label>
                <span class="title-check-commercial-mail"> Société</span>
              </template>
            </v-switch>
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-if="dataToUse != null"
              label="Email"
              :persistent-placeholder="true"
              v-model="dataToUse.client_mail"
              outlined
              color="#704ad1"
              class="input-mail-commercial"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="2">
            <v-switch
              class="check-style-custom-obligee-mail"
              v-if="dataToUse != null"
              v-model="dataToUse.send_to_vendeur"
              value="yes"
              unchecked-value="no"
              color="#704ad1"
            >
              <template v-slot:label>
                <span class="title-check-commercial-mail"> Commercial</span>
              </template>
            </v-switch>
            <v-row> </v-row>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-if="dataToUse != null"
              label="Email"
              :persistent-placeholder="true"
              v-model="dataToUse.vendeur_mail"
              outlined
              color="#704ad1"
              class="input-mail-commercial"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </div>
    <div class="template-mail-filiale-selected">
      <v-row>
        <v-col cols="6">
          <div class="title-editeur-template">Template Email</div>
          <editor
            v-model="dataToUse.content_mail"
            :init="computedDataTinymce.init"
            api-key="fkvny27pjoyigjbydpscwei8n0785pudavymzl4qemdm7pzg"
          ></editor>
        </v-col>
        <v-col cols="6">
          <div class="title-editeur-template">Template Pièce jointe</div>
          <editor
            v-model="dataToUse.content_pdf"
            :init="computedDataTinymce.init"
            api-key="fkvny27pjoyigjbydpscwei8n0785pudavymzl4qemdm7pzg"
          ></editor>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<!-- <script src="//cdn.tinymce.com/4/tinymce.min.js"></script> -->
<script>
import Editor from '@tinymce/tinymce-vue';

import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      email: null,
      errors: null,
      loading: false,
      userSelectedExtraMail: null
    };
  },
  props: {
    loader: { default: false },
    dataToUse: { required: true },
    error: { default: null }
  },
  methods: {
    ...mapActions(['setLisImage', 'addExtraMail'])
  },
  computed: {
    ...mapGetters(['getImageTemplateList']),
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url };
      });
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            );
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          image_title: true,
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData();
            param.append('image', blobInfo.blob());
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url);
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message);
              });
          }
        }
      };
    }
  },
  components: {
    Editor
  }
};
</script>

<style lang="scss" scoped>
.content-mail-appel-commercial {
  height: calc(100vh - 114px);
  .template-mail-filiale-selected {
    width: 100%;
  }
  .title-editeur-template {
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
  }
}
.message-error {
  color: red;
  list-style: none;
}
.title-check-commercial-mail {
  color: #704ad1;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}
</style>
<style lang="scss">
.content-mail-appel-commercial .tox-tinymce {
  height: calc(100vh - 176px) !important;
}
</style>
